<template>
  <div :key="'detailInvoiceProductsTable_' + forceUpdate" class="crud-detail-product-fieldset">     
    <b-row :class="[{'block-product-add':crud.form.detail_register.editing}]">
      <b-col :lg="7 + widthShowDiscount" :md="7 + widthShowDiscount">
        <b-row>
          <b-col lg="6" md="6" id="container_detail_product_select">
            <FindObject :key="crud.form.detail_register.forceRenderSelectProduct"
                        render="search"
                        type="products" 
                        @select-object="loadProducts($event)" 
                        :valueID="crud.form.detail_register.product_id"
                        :where="conditionProductsActive"
                        :disabled="onlyDiscount"
                        :persistLastSearch="persistLastSearchProducts"
                        id="detail_product_select" 
                        title="Buscar por código de barra (F3) | Buscador (F4)" 
                        ref="searchProducts"/> 

            <b-popover target="detail_product_select" 
                       triggers="hover focus click" 
                       placement="auto" 
                       container="container_detail_product_select"                       
                       v-if="crud.form.detail_register.selectedProduct">                       
              <template #title>Stock</template>              
              <table class="table table-hover">
                <tbody>
                  <tr>
                    <td class="detail-product-stock-title-table-custom align-middle" style="padding-right:5px !important; padding-left:5px !important;">Depósito</td>
                    <td class="align-middle" style="padding-left:5px !important;">
                      {{parseFloat(this.crud.form.detail_register.selectedProduct.stock).toFixed(2)}} {{this.crud.form.detail_register.selectedProduct.unit_measurement_purchase_default.reference}}
                    </td>
                  </tr>
                  <tr>
                    <td class="detail-product-stock-title-table-custom align-middle" style="padding-right:5px !important; padding-left:5px !important;">Pendiente</td>
                    <td class="align-middle" style="padding-left:5px !important;">
                      {{parseFloat(this.crud.form.detail_register.selectedProduct.stock_pending).toFixed(2)}} {{this.crud.form.detail_register.selectedProduct.unit_measurement_purchase_default.reference}}
                    </td>
                  </tr>
                </tbody>
              </table>                
            </b-popover>
          </b-col>            
          
          <b-col lg="6" md="6">                          
            <div v-if="!haveOnlyProduct">
              <div v-if="haveColorsWaist">
                <!-- COLOR Y TALLE --> 
                <b-row>
                  <b-col lg="6" md="6" v-if="crud.form.detail_register.opt_color.length>0">
                    <b-form-group label="Color" :disabled="onlyDiscount">
                      <v-select :options="crud.form.detail_register.opt_color" 
                                v-model="crud.form.detail_register.color" 
                                placeholder="Color" 
                                :multiple="false" 
                                :select-on-tab="true"
                                @input="selectColorCombinado"></v-select>
                    </b-form-group>
                  </b-col>                            
                  <b-col lg="6" md="6" v-if="crud.form.detail_register.opt_waist.length>0">
                    <b-form-group label="Talle" :disabled="onlyDiscount">                                
                      <v-select :options="crud.form.detail_register.opt_waist" 
                                v-model="crud.form.detail_register.waist" 
                                placeholder="Talle" 
                                :multiple="false" 
                                :select-on-tab="true"
                                @input="selectWaistCombinado"></v-select>                      
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <!-- COLOR -->
                <div v-if="haveOnlyColors">
                  <b-row>
                    <b-col lg="6" md="6">
                      <b-form-group label="Color" :disabled="onlyDiscount">
                        <v-select :options="crud.form.detail_register.opt_color" 
                                  v-model="crud.form.detail_register.color" 
                                  placeholder="Color" 
                                  :multiple="false" 
                                  :select-on-tab="true"
                                  @input="selectColorSimple"></v-select>
                      </b-form-group>                              
                    </b-col>
                  </b-row>
                </div>

                <!-- TALLE -->
                <div v-if="haveOnlyWaist">
                  <b-row>
                    <b-col lg="6" md="6">                                
                      <b-form-group label="Talle" :disabled="onlyDiscount">                                
                        <v-select :options="crud.form.detail_register.opt_waist" 
                                  v-model="crud.form.detail_register.waist" 
                                  placeholder="Talle" 
                                  :multiple="false" 
                                  :select-on-tab="true"
                                  @input="selectWaistSimple"></v-select>                      
                      </b-form-group>                              
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>                          
          </b-col>
        </b-row>
      </b-col>
      <b-col :lg="5 - widthShowDiscount" :md="5 - widthShowDiscount">
        <b-row>
          
          <b-col :lg="3 + widthShowDiscount" :md="3 + widthShowDiscount">
            <b-form-group label="Cantidad" :disabled="onlyDiscount" v-if="crud.form.detail_register.selectedProduct">
              <b-form-input v-model="crud.form.detail_register.quantity" 
                            type="number" 
                            min="0" 
                            step="1" 
                            class="form-control"
                            size="sm"
                            ref="inputQuantity"/>              
            </b-form-group>
          </b-col>                   

          <b-col :lg="4 + widthShowDiscount" :md="4 + widthShowDiscount" class="pl-0 pr-0">
            <div v-if="crud.form.detail_register.selectedProduct" id="detail_product_amount">
              <div  v-if="crud.form.detail_register.unit_measurement">                  
                <b-form-group v-if="crud.form.detail_register.currency" :disabled="onlyDiscount"> 
                  <template #label>Importe <b-icon icon="info-circle-fill" id="detail_product_amount" title="Más Información"></b-icon></template>
                  <b-input-group :prepend="crud.form.detail_register.currency.symbol" size="sm" class="crud-detail-product-prepend-amount">
                    <b-form-input v-model="crud.form.detail_register.amount" 
                          type="number" 
                          :disabled="!editPrice"
                          :readonly="!editPrice"
                          min="0" 
                          step="0.01" 
                          class="form-control"/>                      

                    <template #append>
                      <b-dropdown :text="crud.form.detail_register.unit_measurement.reference" 
                                  :title="crud.form.detail_register.unit_measurement.name" 
                                  variant="secondary"
                                  class="curd-detail-product-select-unit-measurement"
                                  v-if="Object.keys(arr.equivalences).length">
                        <b-dropdown-item v-for="element in arr.equivalences" 
                                        :key="element.id" 
                                        @click="setUnitMeasurementAlternativeRecalculePrice(element)">
                          {{element.name}} ({{element.reference}})
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-button v-else size="sm" variant="secondary" class="curd-detail-product-select-unit-measurement" :text="crud.form.detail_register.unit_measurement.reference">
                        {{crud.form.detail_register.unit_measurement.reference}} 
                      </b-button>
                    </template>                            
                  </b-input-group>
                </b-form-group>
                <b-form-group v-else size="sm" :disabled="onlyDiscount"> 
                  <template #label>Importe <b-icon icon="info-circle-fill" id="detail_product_amount" title="Más Información"></b-icon></template>
                  <b-input-group prepend="$" size="sm" class="crud-detail-product-prepend-amount">
                    <b-form-input v-model="crud.form.detail_register.amount" 
                          type="number" 
                          :disabled="!editPrice"
                          :readonly="!editPrice"
                          min="0" 
                          step="0.01"                        
                          class="form-control"/>   

                          <template #append>
                            <b-dropdown :text="crud.form.detail_register.unit_measurement.reference" 
                                        :title="crud.form.detail_register.unit_measurement.name" 
                                        variant="secondary"
                                        class="curd-detail-product-select-unit-measurement"
                                        v-if="Object.keys(arr.equivalences).length">
                              <b-dropdown-item v-for="element in arr.equivalences" 
                                              :key="element.id" 
                                              @click="setUnitMeasurementAlternativeRecalculePrice(element)">
                                {{element.name}} ({{element.reference}})
                              </b-dropdown-item>
                            </b-dropdown>
                            <b-button v-else size="sm" variant="secondary" class="curd-detail-product-select-unit-measurement" :text="crud.form.detail_register.unit_measurement.reference">
                              {{crud.form.detail_register.unit_measurement.reference}} 
                            </b-button>
                          </template>                                               
                  </b-input-group>
                </b-form-group>  
              </div>
              <div v-else>
                <b-form-group v-if="crud.form.detail_register.currency" :disabled="onlyDiscount"> 
                  <template #label>Importe <b-icon icon="info-circle-fill" id="detail_product_amount" title="Más Información"></b-icon></template>
                  <b-input-group :prepend="crud.form.detail_register.currency.symbol" size="sm" class="crud-detail-product-prepend-amount">
                    <b-form-input v-model="crud.form.detail_register.amount" 
                          type="number" 
                          :disabled="!editPrice"
                          :readonly="!editPrice"
                          min="0" 
                          step="0.01" 
                          class="form-control"/>                      
                  </b-input-group>
                </b-form-group>
                <b-form-group  v-else size="sm" :disabled="onlyDiscount"> 
                  <template #label>Importe <b-icon icon="info-circle-fill" id="detail_product_amount" title="Más Información"></b-icon></template>
                  <b-input-group prepend="$" size="sm" class="crud-detail-product-prepend-amount">
                    <b-form-input v-model="crud.form.detail_register.amount" 
                          type="number" 
                          :disabled="!editPrice"
                          :readonly="!editPrice"
                          min="0" 
                          step="0.01"                        
                          class="form-control"/>                      
                  </b-input-group>
                </b-form-group> 
              </div>  

              <b-popover target="detail_product_amount" 
                        triggers="hover focus click" 
                        placement="topleft" 
                        container="container_detail_product_select"                       
                        v-if="crud.form.detail_register.amount">                       
                <template #title>PRECIO UNITARIO</template>              
                <div class="crud-detail-product-importe-iva">                       
                  <b-row>
                    <b-col md="12">
                      <span style="color:black; font-size:12px;">
                        C/IVA
                      </span>
                    </b-col>
                    <b-col md="12">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.crud.form.detail_register.selectedProduct.list.currency.code}).format(calcPriceIva(this.crud.form.detail_register.amount, this.crud.form.detail_register.selectedProduct.amount.aliquot_iva))}}
                    </b-col>
                  </b-row>                  
                  <b-row v-if="methodPayment && parseFloat(methodPayment.surcharge) > 0">
                    <b-col md="12">
                      <span style="color:black; font-size:12px;">                        
                        C/IVA | {{this.methodPayment.name}} ({{this.methodPayment.surcharge}}%):
                      </span>
                    </b-col>
                    <b-col md="12">                                            
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.crud.form.detail_register.selectedProduct.list.currency.code}).format(calcPriceIva(this.crud.form.detail_register.amount, this.crud.form.detail_register.selectedProduct.amount.aliquot_iva, this.methodPayment.surcharge))}}                      
                    </b-col>
                  </b-row>                  
                </div>                
              </b-popover>    
            </div>        
          </b-col>

          <b-col lg="3" md="3" v-if="showDiscount && crud.form.detail_register.selectedProduct">
            <b-form-group label="Descuento">                         
              <b-input-group append="%" size="sm" class="crud-detail-product-append-discount">
                <b-form-input v-model="crud.form.detail_register.discount" 
                      type="number" 
                      min="0" 
                      max="100"
                      step="1" 
                      class="form-control"
                      size="sm"/>                      
              </b-input-group>
            </b-form-group>              
          </b-col>        

          <b-col lg="1" md="1" class="crud-new-orders-button-add" v-if="crud.form.detail_register.selectedProduct">
            <b-button @click="addItem()" 
                      v-b-tooltip.hover 
                      :variant="crud.form.detail_register.button.variant"
                      :title="crud.form.detail_register.button.title"
                      size="sm">
              <i :class="crud.form.detail_register.button.icon"></i>
            </b-button>                                                    
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12" v-if="crud.form.detail_register.editing" class="block-product-add-div">
        <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="12">                  
        <b-table class="mb-0 crud-order-table-detail"
          responsive="sm"
          head-variant="dark"
          :hover="true"
          :small="true"
          :fixed="false"
          :items="crud.form.detail"
          :fields="tableDetailNewOrder">
          
          <template v-slot:cell(code)="data">  
            {{data.item.product.code}}
          </template>  

          <template v-slot:cell(product)="data">  
            {{data.item.product.name}}
            <span v-if="data.item.product.color">                                                        
              - <i>{{data.item.product.color.name}}</i>
            </span>
                                    
            <span v-if="data.item.product.waist && data.item.product.waist.name">
              - <i>{{data.item.product.waist.name}}</i>
            </span>     
          </template>   

          <template v-slot:cell(list)="data">              
            <span v-if="data.item.price_list">                                                        
              {{data.item.price_list.reference}}
            </span>              
          </template>   

          <template v-slot:cell(quantity)="data">                            
            {{ parseFloat(data.item.quantity).toFixed(2) }} 
            <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
          </template>   

          <template v-slot:cell(amount)="data">  
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(data.item.amount)}}                          
          </template>   

          <template v-slot:cell(subtotal)="data">  
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(getRegSubTotals(data.item))}}                          
          </template>   

          <template v-slot:cell(discount)="data">  
            ({{data.item.discount}}%)                          
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(getRegDiscount(data.item))}}                          
          </template>   
          
          <template v-slot:cell(total)="data">  
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(getRegTotals(data.item))}}                          
          </template>   

          <template v-slot:cell(f_action)="data">  
            <b-dropdown right 
                        text="" 
                        size="sm" 
                        variant="outline-dark"  
                        :ref="'actionItem_' + data.index"
                        :disabled="crud.form.detail_register.button.editing">                      
              <b-dropdown-item @click="editItem(data.item, data.index)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(data.index)" style="color:red;" v-if="!onlyDiscount">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>     
            </b-dropdown>                                
          </template>                    
        </b-table>                  
      </b-col>
    </b-row>

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD FACILITY LOAD DATA COLOR / WAIST -->
    <b-modal v-model="modal.facility.active"
            header-bg-variant="dark"
            header-text-variant="white"
            :size="widthModalFacility">
      <div slot="modal-header">
        {{this.modal.facility.title}}
      </div>

      <b-row>
        <b-col lg="12">          
          <div v-if="crud.facility.type=='colors'">             
            <div v-if="(crud.facility.products.length > 0) && (crud.facility.products.length <= crud.facility.totalItems)">
              <div v-for="(value, index) in orderFacility" :key="index">              
                <b-row>
                  <b-col lg="5" md="5">                  
                    <b-form-group label="Color">
                      {{value.item.name}}
                    </b-form-group>
                  </b-col>
                  <b-col :lg="2 + widthShowDiscount" :md="2 + widthShowDiscount">
                    <b-form-group label="Cantidad">
                      <b-form-input v-model="value.quantity" 
                            type="number" 
                            min="0" 
                            step="1"
                            size="sm" 
                            class="form-control"/>                      
                    </b-form-group>
                  </b-col>                    
                  <b-col :lg="3 + widthShowDiscount" :md="3 + widthShowDiscount">
                    <b-form-group label="Importe" v-if="value.currency"> 
                      <b-input-group :prepend="value.currency.symbol" size="sm">
                        <b-form-input v-model="value.amount" 
                              type="number" 
                              min="0" 
                              step="0.01" 
                              size="sm" 
                              class="form-control"
                              :disabled="!editPrice"
                              :readonly="!editPrice"/>                      
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label="Importe" v-else> 
                      <b-input-group prepend="$">
                        <b-form-input v-model="value.amount" 
                              type="number"                             
                              min="0" 
                              step="0.01" 
                              size="sm" 
                              clasmds="form-control"
                              :disabled="!editPrice"
                              :readonly="!editPrice"/>                      
                      </b-input-group>
                    </b-form-group>                          
                  </b-col> 
                  <b-col lg="2" md="2" v-if="showDiscount">
                    <b-form-group label="Descuento">                         
                      <b-input-group append="%" size="sm">
                        <b-form-input v-model="value.discount" 
                              type="number" 
                              min="0" 
                              max="100"
                              step="1" 
                              class="form-control"
                              size="sm"/>                      
                      </b-input-group>
                    </b-form-group>              
                  </b-col>                                   
                </b-row>
              </div>
            </div>
            <div v-else>
              <b-alert variant="info" show>Ya cargo todos los colores</b-alert>
            </div>
          </div>

          <div v-if="crud.facility.type=='waist'">
            <div v-if="(crud.facility.products.length > 0) && (crud.facility.products.length <= crud.facility.totalItems)">
              <div v-for="(value, index) in orderFacility" :key="index">              
                <b-row>
                  <b-col lg="5" md="5">                  
                    <b-form-group label="Talle">
                      {{value.item.name}}
                    </b-form-group>
                  </b-col>
                  <b-col :lg="2 + widthShowDiscount" :md="2 + widthShowDiscount">
                    <b-form-group label="Cantidad">
                      <b-form-input v-model="value.quantity" 
                            type="number" 
                            min="0" 
                            step="1"
                            size="sm" 
                            class="form-control"/>                      
                    </b-form-group>
                  </b-col>                    
                  <b-col :lg="3 + widthShowDiscount" :md="3 + widthShowDiscount">
                    <b-form-group label="Importe" v-if="value.currency"> 
                      <b-input-group :prepend="value.currency.symbol" size="sm">
                        <b-form-input v-model="value.amount" 
                              type="number" 
                              min="0" 
                              step="0.01" 
                              size="sm" 
                              class="form-control"
                              :disabled="!editPrice"
                              :readonly="!editPrice"/>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label="Importe" v-else> 
                      <b-input-group prepend="$">
                        <b-form-input v-model="value.amount" 
                              type="number"                             
                              min="0" 
                              step="0.01" 
                              size="sm" 
                              class="form-control"
                              :disabled="!editPrice"
                              :readonly="!editPrice"/>
                      </b-input-group>
                    </b-form-group>                          
                  </b-col>    
                  <b-col lg="2" md="2" v-if="showDiscount">
                    <b-form-group label="Descuento">                         
                      <b-input-group append="%" size="sm">
                        <b-form-input v-model="value.discount" 
                              type="number" 
                              min="0" 
                              max="100"
                              step="1" 
                              class="form-control"
                              size="sm"/>                      
                      </b-input-group>
                    </b-form-group>              
                  </b-col>                                
                </b-row>
              </div>
            </div>
            <div v-else>
              <b-alert variant="info" show>Ya cargo todos los talles</b-alert>
            </div>
          </div>        
        </b-col>
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="cancelFacility()">Cancelar</b-button>
        <b-button variant="dark" @click="saveFacility()">Aceptar</b-button>          
      </div>
    </b-modal>      

    <!-- CRUD MULTIPLE LIST PRICE -->
    <b-modal v-model="modal.multipleListPrice.active"
            header-bg-variant="dark"
            header-text-variant="white"            
            size="lg"
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close>
      <div slot="modal-header">
        {{this.modal.multipleListPrice.title}}
      </div>

      <b-row>
        <b-col md="12" class="mb-2">          
          <div class="detail-product-multiple-list-price-title">
            <span class="detail-product-multiple-list-price-code">
              {{this.modal.multipleListPrice.code}}   
            </span><br>            
            {{this.modal.multipleListPrice.product}}            
          </div>              
        </b-col>        
        <b-col lg="12">          
          <b-table 
            class="mb-0"
            responsive="sm"
            head-variant="dark"
            :hover="true"
            :small="true"
            :fixed="false"
            :items="tableMultipleListPrice.items"
            :fields="tableMultipleListPrice.fields">
            
            <template v-slot:cell(list)="data">  
              <div v-if="data.item.list">
                {{data.item.list.reference}}
              </div>
            </template>  

            <template v-slot:cell(price_list)="data">                
              <div v-if="data.item.amount && (data.item.amount.unit_measurement_initial.reference != data.item.amount.unit_measurement_result.reference)">
                <div v-if="data.item && data.item.list && data.item.list.currency">
                  <span v-if="data.item.amount">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount.amount_initial, data.item.amount.desc, data.item))}}
                  </span>
                  <span v-if="data.item.amount.unit_measurement_initial">{{data.item.amount.unit_measurement_initial.reference}}</span>             
                </div>
              </div>
            </template>

            <template v-slot:cell(price_public)="data">   
              <div v-if="data.item && data.item.list && data.item.list.currency">
                <span v-if="data.item.amount">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount.amount_total, data.item.amount.desc, data.item))}}
                </span>
                <span v-if="data.item.amount && data.item.amount.unit_measurement_result">
                  {{data.item.amount.unit_measurement_result.reference}}
                </span>
              </div>
            </template>

            <template v-slot:cell(desc)="data">       
              <div v-if="data.item.amount && parseFloat(data.item.amount.desc) > 0">
                {{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(data.item.amount.desc)/100)}}
              </div>
            </template>

            <template v-slot:cell(price_desc)="data">                
              <div v-if="data.item.amount">
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount.amount_total)}}                
                <span v-if="data.item.amount.unit_measurement_result">{{data.item.amount.unit_measurement_result.reference}}</span>
              </div>
            </template>

            <template v-slot:cell(action)="data">  
              <b-button 
                variant="outline-dark" 
                size="sm" 
                @click="selectMultipleListPrice(data.item)"
                :ref="'actionPriceItem_' + data.index">                
                <b-icon icon="chevron-double-right"></b-icon>
              </b-button>
            </template>                          
          </b-table>
        </b-col>
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" size="sm" class="mr-1" @click="cancelMultipleListPrice()">Cancelar</b-button>        
      </div>
    </b-modal>   

    <!-- CRUD BARCODE -->
    <b-modal v-model="modal.barcode.active"
            header-bg-variant="dark"
            header-text-variant="white"            
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close>
      <div slot="modal-header">
        {{this.modal.barcode.title}}
      </div>

      <b-row>
        <b-col md="12" class="mb-2">      
          <div class="text-center">
            <b-icon icon="upc-scan" animation="cylon" font-scale="4" class="mb-3"></b-icon>
            
            <b-form-input v-model="modal.barcode.value" 
                  type="text"                     
                  size="sm" 
                  class="form-control"
                  :disabled="true" 
                  placeholder="Código de Barra" />          
          </div>
        </b-col>
      </b-row>
      <div slot="modal-footer">
        <b-button variant="outline-secondary" size="sm" class="mr-1" @click="cancelScanBarcode()">Cancelar</b-button>        
      </div>      
    </b-modal>
  </div>       
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Funciones from '@/handler/funciones' 
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'    
  import Param from '@/config/parameters'
  import moment from 'moment'

  export default {
    props: {
      customer_id:{
        type: Number,
        required: true,
      },
      price_list_id:{
        type: Number,
        default: 0,
      },     
      reason_id: {
        type: Number,
        default: 0,
      },      
      editPrice: {
        type: Boolean,
        default: false,
      },
      validStock: {
        type: Boolean,
        default: false,
      },
      detailEdit: {
        type: [Array, Object],
        default: null,
      },
      showDiscount: {
        type: Boolean,
        default: true,        
      },
      onlyDiscount: {
        type: Boolean,
        default: false,
      },
      forceUpdate: {
        type: Number,
        default: 0,
      },
      validItemDuplicated: {
        type: Boolean,
        default: true,
      },
      barcodeAddAutomatic: {
        type: Boolean,
        default: false,
      },      
      getItemOnlyPublished: {
        type: Boolean,
        default: false,
      },            
      methodPayment:{
        type: [Object, Array],
        default: () => [],
      },            
    },
    components: {
      FindObject,
    },
    data: () => {
      return {  
        tableDetailNewOrder : [],           
        tableMultipleListPrice : {
          items: [],
          fields: [],
        },
        crud: {
          form: {
            detail_register: {
              opt_color: [],
              opt_waist: [],
              color: null,
              waist: null,
              quantity: 0,
              quantityOld: 0,
              amount: 0,
              discount: 0,
              currency: null,              
              product_id: 0,
              product: null,              
              selectedProduct: null,
              forceRenderSelectProduct: 0,
              unit_measurement: null,
              unit_measurement_id: 0,
              amount_initial: 0,
              price_list_id: 0,
              price_list: null,
              button: {
                icon: 'fa fa-plus',
                title: "Agregar Item (CTRL + SHIFT + '+')",
                variant: 'success',
                editing: false
              },
              editing: false,
            },            
            detail: [],   
            editOrder: false,    
          },
          facility: {            
            type: '',
            products: null,  
            totalItems: 0,       
          }           
        },
        editing: {
          status: false,
          price: 0,
          unit_measurement: null,
          list: null,
        },
        modal: {
          facility: {
            active: false,
            title: '', 
          },
          multipleListPrice: {
            active: false,
            title: '', 
            code: '',
            product: '',
          },
          barcode: {
            active: false,
            title: 'Escaneo código de barras',         
            value: '',
          },       
        },
        arr: {
          equivalences: [],
        },
        configurations: null,       
      }
    },
    watch: {        
      'crud.form.detail_register.selectedProduct': function (newQuestion, oldQuestion) {        
        if(newQuestion) {          
          // le quitamos la validacion if(this.editPrice)  que viene seteado como un parametro del componente
          if(this.editing.status) {
            this.crud.form.detail_register.amount = this.editing.price            
            this.crud.form.detail_register.currency = this.editing.list.currency        

            if(this.editing.unit_measurement) {
              this.crud.form.detail_register.unit_measurement = this.editing.unit_measurement
              this.crud.form.detail_register.unit_measurement_id = this.editing.unit_measurement.id
            }
          } else {
            this.crud.form.detail_register.quantity = this.quantityProducts
            this.crud.form.detail_register.amount = newQuestion.price_simple          
            this.crud.form.detail_register.currency = newQuestion.list.currency          

            if(newQuestion.unit_measurement_sale) {
              this.crud.form.detail_register.unit_measurement = newQuestion.unit_measurement_sale
              this.crud.form.detail_register.unit_measurement_id = newQuestion.unit_measurement_sale.id
            }
          }                            

          this.editing.status = false
          this.editing.price = 0
          this.editing.list = null
          this.editing.unit_measurement = null
        
          if(newQuestion.price_inital == newQuestion.price_simple) {
            this.crud.form.detail_register.amount_initial = newQuestion.amount.amount
          } else {
            if(newQuestion.amount && parseFloat(newQuestion.amount.desc)>0) {
              this.crud.form.detail_register.amount_initial = parseFloat(newQuestion.amount.amount_initial) - ((parseFloat(newQuestion.amount.amount_initial) * parseFloat(newQuestion.amount.desc)) / 100)
            } else {
              this.crud.form.detail_register.amount_initial = newQuestion.amount.amount_initial
            }
          }
          this.crud.form.detail_register.price_list_id = newQuestion.list.id
          this.crud.form.detail_register.price_list = newQuestion.list          
          this.loadEquivalences()                    
        }        
      },
    },  
    computed: {
      // CONDITIONS SELECT
      conditionProductsActive() {
        if(this.getItemOnlyPublished) {
          return [
            {field: 'm_products.active', condition: true},
            {field: 'm_products.published', condition: true}
          ];                    
        } else {
          return [{field: 'm_products.active', condition: true}];    
        }        
      },

      // MODULES ACTIVES
      moduleDepositActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules
        modules.forEach(element => {
          if(element.id == Modules.DEPOSITOS) {
            status = true
          }
        })
        return status
      },      

      // CONFIGURACION DE PRODUCTO - VARIEDAD
      haveColorsWaist() {
        if(this.crud.form.detail_register.product) {
          if(this.crud.form.detail_register.product.colors_waist.length) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      haveOnlyColors() {     
        if(this.crud.form.detail_register.product) { 
          if( this.crud.form.detail_register.product.colors.length && 
              !this.crud.form.detail_register.product.waist.length && 
              !this.crud.form.detail_register.product.colors_waist.length) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      haveOnlyWaist() {
        if(this.crud.form.detail_register.product) { 
          if( this.crud.form.detail_register.product.waist.length && 
              !this.crud.form.detail_register.product.colors.length &&
              !this.crud.form.detail_register.product.colors_waist.length) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      haveOnlyProduct() {
        if(this.crud.form.detail_register.product) { 
          if( !this.crud.form.detail_register.product.colors.length && 
              !this.crud.form.detail_register.product.waist.length &&
              !this.crud.form.detail_register.product.colors_waist.length) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },  

      // GET COLOR
      getColorWaistUnique() {        
        var arrColorsWaist = []         
        var arrColorsWaistTMP = []                  
        if(this.crud.form.detail_register.product) {          
          this.crud.form.detail_register.product.colors_waist.forEach(element => {
            if(!arrColorsWaistTMP.includes(element.color.id)) {
              arrColorsWaist.push(element)
              arrColorsWaistTMP.push(element.color.id)
            }          
          });       
          return arrColorsWaist
        }       
      },
      getWaistByColor() {                
        var waist = []        
        if(this.crud.form.detail_register.color) {
          if(this.crud.form.detail_register.color.code) {            
            this.crud.form.detail_register.product.colors_waist.forEach(element => {                        
              if(element.color.id == this.crud.form.detail_register.color.code.id) {              
                waist.push(element)
              }
            });
          }
        }
        return waist
      },               

      // CONFIGURATION SHOW DISCOUNT
      widthModalFacility () {
        if(this.showDiscount) {
          return 'lg'
        } else {
          return 'md'
        }
      },
      widthShowDiscount () {
        if(this.showDiscount) {
          return 0
        } else {
          return 1
        }
      },    

      // FACILITY
      orderFacility() {  
        if(this.crud.facility.type=='colors') {
          if((this.crud.facility.products.length > 0) && (this.crud.facility.products.length <= this.crud.facility.totalItems)) {                              
            return Funciones.sortArrayString(this.crud.facility.products,'itemName',1)                             
          } else {
            return []
          }
        }

        if(this.crud.facility.type=='waist')       {
          if((this.crud.facility.products.length > 0) && (this.crud.facility.products.length <= this.crud.facility.totalItems)) {
            return Funciones.sortArrayInteger(this.crud.facility.products,'itemOrder',1)                             
          } else {
            return []
          }
        }        
      }, 
      
      // VALUES DEFAULT
      quantityProducts() {
        if(this.configurations) {
          return parseInt(this.configurations[0].value_number);
        } else {
          return 0;
        }
      },              
      persistLastSearchProducts() {                      
        if(this.configurations) {
          return this.configurations[9].value_boolean
        } else {
          return false
        }        
      },

    },
    mounted() {
      this.loadConfigurations()            
      this.configFieldsTable()
      this.loadItems()      
    },
    created() {
      window.addEventListener("keydown", this.scanBarcode);          
      window.addEventListener("keydown", this.handlerSearchProducts);
    },
    destroyed() {
      window.removeEventListener("keydown", this.scanBarcode);    
      window.removeEventListener("keypress", this.captureBarcode);
      
      window.removeEventListener("keydown", this.handlerSearchProducts);  
    },    
    methods: {
      configFieldsTable() {
        // TABLE DETAILS
        this.tableDetailNewOrder.push({key: 'code', label: 'Código', class:"align-middle"})              
        this.tableDetailNewOrder.push({key: 'product', label: 'Producto', class:"align-middle"})      
        this.tableDetailNewOrder.push({key: 'list', label: 'Lista', class:"align-middle text-center"})        
        this.tableDetailNewOrder.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-center"})
        this.tableDetailNewOrder.push({key: 'amount', label: 'Importe', class:"align-middle text-right"})        
        
        if(this.showDiscount) {
          this.tableDetailNewOrder.push({key: 'subtotal', label: 'Subtotal', class:"align-middle text-right"})
          this.tableDetailNewOrder.push({key: 'discount', label: 'Descuento', class:"align-middle text-right"})
        }        

        this.tableDetailNewOrder.push({key: 'total', label: 'Total', class:"align-middle text-right"})        
        this.tableDetailNewOrder.push({key: 'f_action', label:'', class:"align-middle text-right"})

        // TABLE MULTIPLE PRICE
        this.tableMultipleListPrice.fields.push({key: 'list', label: 'Lista', class:"align-middle", width:"35%"})
        this.tableMultipleListPrice.fields.push({key: 'price_list', label: '', class:"align-middle text-right", width:"15%"})
        this.tableMultipleListPrice.fields.push({key: 'price_public', label: 'Precio', class:"align-middle text-right", width:"15%"})
        this.tableMultipleListPrice.fields.push({key: 'desc', label: 'Descuento', class:"align-middle text-right", width:"10%"})
        this.tableMultipleListPrice.fields.push({key: 'price_desc', label: 'Precio', class:"align-middle text-right", width:"15%"})
        this.tableMultipleListPrice.fields.push({key: 'action', label: '', class:"align-middle text-right", width:"10%"})
      },
      loadConfigurations() {
        var result = serviceAPI.obtenerConfiguracion()

        result.then((response) => {
          var data = response.data                  
          this.configurations = data                          
        })      
      },       
      loadItems() {          
        if(this.detailEdit) {
          this.detailEdit.forEach(element => {            
            var arrItem = { 
              product: element.product, 
              quantity: parseFloat(element.quantity), 
              quantityOld: parseFloat(element.quantity),
              currency: element.currency,                        
              amount: parseFloat(element.amount),   
              discount: (element.itemDetail ? parseFloat(element.itemDetail.percentage_discount) : 0),                       
              unit_measurement: element.unit_measurement,
              price_list: element.price_list
            }

            this.crud.form.detail.push(arrItem)                  
            this.crud.form.editOrder = true
          });
          
          this.$emit('load-detail', this.crud.form.detail)
        }
      },      
      loadProducts (object) {
        if(object){
          this.crud.form.detail_register.product = object
          this.crud.form.detail_register.product_id = object.id     
          
          this.crud.form.detail_register.color = null
          this.crud.form.detail_register.waist = null
                    
          this.crud.form.detail_register.amount = 0
          this.crud.form.detail_register.currency = null 
          this.crud.form.detail_register.opt_color = []
          this.crud.form.detail_register.opt_waist = []
          //this.crud.form.detail_register.forceRenderSelectProduct = object.id (lo comento para que no vuelva a entrar al loadProduct)
          this.crud.form.detail_register.selectedProduct = null             
          this.crud.form.detail_register.unit_measurement = object.unit_measurement_sale
          this.crud.form.detail_register.unit_measurement_id = object.unit_measurement_sale.id
                              
          if(object.colors_waist.length) {
            var colorUnique = this.getColorWaistUnique          
            if(colorUnique) {
              colorUnique.forEach(element => {
                this.crud.form.detail_register.opt_color.push({code: element.color, label: element.color.name})
              });
            }
            return false 
          } 
          
          if(object.colors.length) {
            var detail_register = []
            var colorUnique = object.colors          
                        
            if(colorUnique) {              
              colorUnique.forEach(element => {  
                if(this.editing.status) {
                  this.crud.form.detail_register.opt_color.push({code: element.color, label: element.color.name})
                } else {  
                  if(!this.price_list_id) {
                    this.crud.form.detail_register.opt_color.push({code: element.color, label: element.color.name})
                  } else {
                    if(this.showItemCrudFacilityColors(element)) {               
                      var variantSelect = element                        
                      variantSelect['source'] = 'colors'

                      if(this.customer_id) {                    
                        var result = serviceAPI.obtenerPrecioProducto({
                          product: variantSelect,
                          customer_id: this.customer_id,
                          price_list_id: this.price_list_id,
                          reason_id: this.reason_id
                        })        
                        result.then((response) => {
                          var data = response.data   

                          if(!data.undefined) {                          
                            detail_register.push({
                              quantity: 0,
                              currency: data.list.currency,
                              amount: data.price_simple,
                              item: data.color,
                              itemName: data.color.name,
                              itemOrder: 0,
                              product: data,
                              id: element.id,
                              discount: 0,
                              unit_measurement: object.unit_measurement_sale,
                            })                    
                          }                                    
                        })             
                      } else {                      
                        detail_register.push({
                          quantity: 0,
                          currency: null,
                          amount: 0,
                          item: null,
                          itemName: '',
                          itemOrder: 0,
                          product: null,
                          id: 0,
                          discount: 0,
                          unit_measurement: null,
                        })                                            
                      }    
                    }

                    this.crud.facility.type = 'colors'
                    this.crud.facility.totalItems = object.colors.length
                    this.crud.facility.products = detail_register

                    this.modal.facility.active = true
                    this.modal.facility.title = "Cargar Colores" 
                  }         
                }                                                
              });              
            }    
            return false                              
          }

          if(object.waist.length) {
            var detail_register = []
            var waistUnique = object.waist

            if(waistUnique) {
              waistUnique.forEach(element => {  
                if(this.editing.status) {
                  this.crud.form.detail_register.opt_waist.push({code: element.waist, label: element.waist.name})
                } else {
                  if(!this.price_list_id) {
                    this.crud.form.detail_register.opt_waist.push({code: element.waist, label: element.waist.name})
                  } else {
                    if(this.showItemCrudFacilityWaist(element)) {               
                      var variantSelect = element                        
                      variantSelect['source'] = 'waist'

                      if(this.customer_id) {                    
                        var result = serviceAPI.obtenerPrecioProducto({
                          product: variantSelect,
                          customer_id: this.customer_id,
                          price_list_id: this.price_list_id,
                          reason_id: this.reason_id
                        })        
                        result.then((response) => {
                          var data = response.data   
                          
                          if(!data.undefined) {     
                            detail_register.push({
                              quantity: 0,
                              currency: data.list.currency,
                              amount: data.price_simple,
                              item: data.waist,
                              itemName: data.waist.name,
                              itemOrder: data.waist.order,
                              product: data,
                              id: element.id,
                              discount: 0,
                              unit_measurement: object.unit_measurement_sale,
                            })                    
                          }                                    
                        })             
                      } else {                      
                        detail_register.push({
                          quantity: 0,
                          currency: null,
                          amount: 0,
                          item: null,
                          itemName: '',
                          itemOrder: 0,
                          product: null,
                          id: 0,
                          discount: 0,
                          unit_measurement: null,
                        })                                            
                      }    
                    }

                    this.crud.facility.type = 'waist'
                    this.crud.facility.totalItems = object.waist.length
                    this.crud.facility.products = detail_register

                    this.modal.facility.active = true
                    this.modal.facility.title = "Cargar Talles" 
                  }
                }
              });
            }
            return false
          }    
          
          if(!object.colors_waist.length && !object.colors.length && !object.waist.length) {            
            this.selectedProduct()     
                        
            setTimeout(()=> {
              if(this.$refs.inputQuantity) {     
                this.$refs.inputQuantity.focus()         
                this.$refs.inputQuantity.select()
              }
            },500)
          }

        } else {                    
          this.crud.form.detail_register.product_id = 0                  
          this.crud.form.detail_register.product = null
          
          this.crud.form.detail_register.color = null
          this.crud.form.detail_register.waist = null                        
          this.crud.form.detail_register.quantity = this.quantityProducts
          this.crud.form.detail_register.amount = 0  
          this.crud.form.detail_register.discount = 0
          this.crud.form.detail_register.currency = null              
          this.crud.form.detail_register.opt_color = []
          this.crud.form.detail_register.opt_waist = []   
          this.crud.form.detail_register.selectedProduct = null
          this.crud.form.detail_register.unit_measurement = null
          this.crud.form.detail_register.unit_measurement_id = 0

          this.crud.form.detail_register.button.title = "Agregar Item (CTRL + SHIFT + '+')"
          this.crud.form.detail_register.button.icon = "fa fa-plus"
          this.crud.form.detail_register.button.variant = "success"          
        }
      },    

      // SELECCION PRODUCTO-VARIEDAD 
      selectColorCombinado(item) {        
        this.crud.form.detail_register.color = item
        this.crud.form.detail_register.waist = null

        this.crud.form.detail_register.opt_waist = []
        var waistUnique = this.getWaistByColor          
        if(waistUnique) {
          waistUnique.forEach(element => {
            this.crud.form.detail_register.opt_waist.push({code: element.waist, label: element.waist.name})
          });
        }         
      },
      selectWaistCombinado(item) {
        this.crud.form.detail_register.waist = item
        this.selectedProduct()        
      },      
      selectColorSimple(item) {
        this.crud.form.detail_register.color = item
        this.selectedProduct()        
      },
      selectWaistSimple(item) {
        this.crud.form.detail_register.waist = item      
        this.selectedProduct()        
      },  

      // PRODUCTO/VARIEDAD SELCCIONADA
      selectedProduct() {        
        var variantSelect = null

        if(this.crud.form.detail_register.product) {
          if(this.haveOnlyProduct) {
            variantSelect = this.crud.form.detail_register.product
            variantSelect['source'] = 'product'
          } else {
            if(this.haveColorsWaist) {
              if(this.crud.form.detail_register.color && this.crud.form.detail_register.waist) {
                this.crud.form.detail_register.product.colors_waist.forEach(element => {                
                  if( element.colors_id == this.crud.form.detail_register.color.code.id && 
                      element.waist_id == this.crud.form.detail_register.waist.code.id ) {                                      
                      variantSelect = element                      
                      variantSelect['unit_measurement_purchase_id'] = this.crud.form.detail_register.product.unit_measurement_purchase_id                      
                      variantSelect['source'] = 'colors_waist'
                  }
                });
              }            
            } else {
              if(this.haveOnlyColors) {                
                if(this.crud.form.detail_register.color) {                  
                  this.crud.form.detail_register.product.colors.forEach(element => {                                       
                    if( element.colors_id == this.crud.form.detail_register.color.code.id ) {                                      
                        variantSelect = element                        
                        variantSelect['unit_measurement_purchase_id'] = this.crud.form.detail_register.product.unit_measurement_purchase_id
                        variantSelect['source'] = 'colors'
                    }
                  })
                }
              } else {
                if(this.haveOnlyWaist) {
                  if(this.crud.form.detail_register.waist) {
                    this.crud.form.detail_register.product.waist.forEach(element => {                   
                      if( element.waist_id == this.crud.form.detail_register.waist.code.id ) {                                      
                          variantSelect = element                          
                          variantSelect['unit_measurement_purchase_id'] = this.crud.form.detail_register.product.unit_measurement_purchase_id
                          variantSelect['source'] = 'waist'
                      }
                    })
                  }
                }            
              }          
            }     
          }             
        }

        if(variantSelect && this.customer_id) {          
          var listPriceQuery = 0
          if(!(this.editPrice && this.editing.status)) {          
            listPriceQuery = this.price_list_id;
          } else {
            listPriceQuery = this.editing.list.id
          }

          let loader = this.$loading.show();
          var result = serviceAPI.obtenerPrecioProducto({
            product: variantSelect,
            customer_id: this.customer_id,
            price_list_id: listPriceQuery,
            reason_id: this.reason_id
          })        
          result.then((response) => {
            loader.hide()

            var data = response.data               
            
            this.tableMultipleListPrice.items = []
            if(Array.isArray(data)) {
              data.forEach(element => {
                if(element.amount) {
                  this.tableMultipleListPrice.items.push(element)
                }
              });
            } else {
              this.tableMultipleListPrice.items.push(data)
            }

            if (Array.isArray(data)) {              
              if(!this.crud.form.detail_register.selectedProduct) {              
                this.modal.multipleListPrice.title = "Selección de Lista de Precios"
                this.modal.multipleListPrice.code = variantSelect.code
                this.modal.multipleListPrice.product = variantSelect.name
                this.modal.multipleListPrice.active = true      
                
                if(this.$refs.actionPriceItem_0) {
                  this.$refs.actionPriceItem_0.focus()
                }  
              }              
            } else {
              this.crud.form.detail_register.selectedProduct = data
            }                       
          })             
        } else {                      
          this.crud.form.detail_register.selectedProduct = null
        }    
      }, 
      selectMultipleListPrice(item) {                
        this.crud.form.detail_register.selectedProduct = item
        this.modal.multipleListPrice.active = false

        setTimeout(()=> {
          if(this.$refs.inputQuantity) {     
            this.$refs.inputQuantity.focus()         
            this.$refs.inputQuantity.select()
          }
        },500)        
      },
      cancelMultipleListPrice() {
        this.modal.multipleListPrice.active = false
      },      
      sumDiscountPrice(amount, desc, item = null, action = 'viewCalc') {                  
        if(item && item.unit_measurement_purchase && item.unit_measurement_sale && item.unit_measurement_purchase.id != item.unit_measurement_sale.id) {        
          if(action == 'viewCalc') {                       
            if(parseFloat(item.amount.amount_initial) == parseFloat(amount)) {
              return parseFloat(item.amount.amount_initial)
            } else {
              if(desc) {
                var base1 = (100 - parseFloat(desc)) / 100                
                if(parseFloat(base1) == 0) {                            
                  return parseFloat(amount)
                } else {                              
                  return parseFloat(amount) / base1
                }                        
              } else {            
                return parseFloat(amount)
              }
            }          
          } 
          
          if(action == 'formEdit') {           
            return parseFloat(amount)
          }
        } else {    
          if(action == 'viewCalc') {        
            if(desc) {
              var base2 = (100 - parseFloat(desc)) / 100                
              if(parseFloat(base2) == 0) {                            
                return parseFloat(amount)
              } else {              
                return parseFloat(amount) / base2
              }                        
            } else {
              return parseFloat(amount)
            }
          }

          if(action == 'formEdit') {           
            return parseFloat(amount)
          }          
        }     
      },
      calcPriceIva(amount, iva=0, surcharge=0) {        
        var price = 0
        if(iva) {
          price = parseFloat(amount) + ((parseFloat(amount) * parseFloat(iva)) / 100)
        }
        if(surcharge) {
          price = parseFloat(price) + ((parseFloat(price) * parseFloat(surcharge)) / 100)
        }
        return price
      },

      // ABM MAIN DETAILS
      addItem() {
        // producto
        if(!this.crud.form.detail_register.selectedProduct) {
          this.$awn.alert('No se seleccionó el producto')          
          return false
        }
                
        // cantidad
        if (this.crud.form.detail_register.quantity == undefined || this.crud.form.detail_register.quantity.toString() == '' || parseFloat(this.crud.form.detail_register.quantity)<=0) {
          this.$awn.alert('La cantidad debe ser mayor a cero')          
          return false          
        }
          
        // amount
        if(this.crud.form.detail_register.amount == undefined || this.crud.form.detail_register.amount.toString() == '') {
          this.crud.form.detail_register.amount = 0
        } else {
          if (parseFloat(this.crud.form.detail_register.amount)<0) {                   
            this.$awn.alert('El precio no puede ser menor a cero')          
            return false          
          }
        }

        // descuento
        if(this.crud.form.detail_register.discount == undefined || this.crud.form.detail_register.discount.toString() == '') {
          this.crud.form.detail_register.discount = 0
        } else {
          if (parseFloat(this.crud.form.detail_register.discount)<0) {      
            this.$awn.alert('El descuento no puede ser menor a cero')          
            return false          
          }
        }
        
        // stock
        if(!this.validStockItem()) {
          this.$awn.alert('Stock Insuficiente') 
          return false
        }

        // duplicados
        if(this.validItemDuplicated) {
          if(!this.validDuplicatedItem()) {
            this.$awn.alert('Item Duplicado')
            return false        
          }
        }

        var arrItem = { 
          product: this.crud.form.detail_register.selectedProduct,
          quantity: parseFloat(this.crud.form.detail_register.quantity),
          quantityOld: parseFloat(this.crud.form.detail_register.quantityOld),
          currency: this.crud.form.detail_register.currency,
          amount: parseFloat(this.crud.form.detail_register.amount),
          discount: parseFloat(this.crud.form.detail_register.discount),
          unit_measurement: this.crud.form.detail_register.unit_measurement,
          price_list: this.crud.form.detail_register.price_list
        }

        this.crud.form.detail.push(arrItem)
        this.$emit('load-detail', this.crud.form.detail)
        
        this.crud.form.detail_register.forceRenderSelectProduct = Math.random()
        this.loadProducts(null)                                    
        
        this.crud.form.detail_register.button.editing = false
        
        /*
        setTimeout(()=> {
          if(this.$refs.actionItem_0) {   
            this.$refs.actionItem_0.focusToggler()                 
          }        
        },100)
        */
      },
      editItem (item, index) {    
        this.crud.form.detail_register.editing = true
        this.crud.form.detail_register.quantity = item.quantity
        this.crud.form.detail_register.quantityOld = item.quantityOld
        this.crud.form.detail_register.discount = item.discount      
        this.crud.form.detail_register.price_list = item.price_list
        
        // le quitamos la validacion if(this.editPrice)  que viene seteado como un parametro del componente
        this.editing.status = true
        this.editing.price = item.amount
        this.editing.unit_measurement = item.unit_measurement
        this.editing.list = item.price_list ? item.price_list : item.product.list
                           
        if(item.product.source == 'product') {             
          this.crud.form.detail_register.product_id = item.product.id
          this.crud.form.detail_register.forceRenderSelectProduct = item.product.id    
          
          this.crud.form.detail_register.editing = false
        } else {          
          if(item.product.source == 'colors_waist') {
            this.crud.form.detail_register.product_id = item.product.products_id      
            this.crud.form.detail_register.forceRenderSelectProduct = item.product.products_id
            
            setTimeout(()=>{
              this.crud.form.detail_register.color = {code:item.product.color, label:item.product.color.name}
              this.selectColorCombinado(this.crud.form.detail_register.color)

              setTimeout(()=>{
                this.crud.form.detail_register.waist = {code:item.product.waist, label:item.product.waist.name}                
                this.selectWaistCombinado(this.crud.form.detail_register.waist) 

                this.crud.form.detail_register.editing = false
              },1000)              
            },1000)                        
          } else {
            if(item.product.source == 'colors') {
              this.crud.form.detail_register.product_id = item.product.products_id
              this.crud.form.detail_register.forceRenderSelectProduct = item.product.products_id
                                          
              setTimeout(()=>{                
                this.crud.form.detail_register.color = {code:item.product.color, label:item.product.color.name}
                this.selectColorSimple(this.crud.form.detail_register.color)

                this.crud.form.detail_register.editing = false
              },1000)                            
            }
            if(item.product.source == 'waist') {
              this.crud.form.detail_register.product_id = item.product.products_id
              this.crud.form.detail_register.forceRenderSelectProduct = item.product.products_id
              
              setTimeout(()=>{                
                this.crud.form.detail_register.waist = {code:item.product.waist, label:item.product.waist.name}                
                this.selectWaistSimple(this.crud.form.detail_register.waist) 

                this.crud.form.detail_register.editing = false
              },1000)                            
            }
          }
        }
        
        this.crud.form.detail_register.button.title = "Editar Item (CTRL + SHIFT + '+')"
        this.crud.form.detail_register.button.icon = "fa fa-save"
        this.crud.form.detail_register.button.variant = "warning"
        this.crud.form.detail_register.button.editing = true
                     
        this.crud.form.detail.splice(index, 1)
        this.$emit('load-detail', this.crud.form.detail)
      },
      deleteItem (index) {
        this.crud.form.detail.splice(index, 1)
        this.$emit('load-detail', this.crud.form.detail)
      },

      // CALCULA DISCOUNT
      getRegDiscount(item) {        
        var totalDiscount = 0
        if(parseFloat(item.discount) > 0) {
          totalDiscount = parseFloat(item.quantity) * (( parseFloat(item.amount) * parseFloat(item.discount) ) / 100)
        }        
        return totalDiscount
      },
      getRegSubTotals(item) {
        var subtotal = parseFloat(item.quantity) * parseFloat(item.amount)
        return subtotal
      },       
      getRegTotals(item) {
        var total = 0
        if(parseFloat(item.discount) > 0) {
          var discount = (parseFloat(item.amount) * parseFloat(item.discount)) / 100
          var amount = parseFloat(item.amount) - parseFloat(discount)

          total = parseFloat(item.quantity) * parseFloat(amount)
        } else {
          total = parseFloat(item.quantity) * parseFloat(item.amount)
        }
        return total
      },      

      // VALIDA STOCK
      validStockItem() {        
        var status = true        
        if(this.validStock) {             
          if(this.crud.form.editOrder) {
            if(this.crud.form.detail_register.selectedProduct) {                   
              if(this.crud.form.detail_register.selectedProduct.unit_measurement_purchase_id == this.crud.form.detail_register.unit_measurement.id) {
                let stockProduct = 0
                if(this.moduleDepositActive) {
                  stockProduct = parseFloat(this.crud.form.detail_register.selectedProduct.stock)
                } else {
                  stockProduct = parseFloat(this.crud.form.detail_register.selectedProduct.stock) + parseFloat(this.crud.form.detail_register.quantityOld)
                }

                if(this.crud.form.detail_register.quantity > stockProduct) {          
                  this.crud.form.detail_register.quantity = stockProduct              
                  var status = false
                }
              }
            }
          } else {
            if(this.crud.form.detail_register.selectedProduct) {                          
              if(this.crud.form.detail_register.selectedProduct.unit_measurement_purchase_id == this.crud.form.detail_register.unit_measurement.id) {
                let stockProduct = parseFloat(this.crud.form.detail_register.selectedProduct.stock) 
                if(this.crud.form.detail_register.quantity >  stockProduct) {          
                  this.crud.form.detail_register.quantity = stockProduct
                  var status = false
                }
              }
            }
          }
        }
        return status
      },  
      
      // VALID DUPLICATED
      validDuplicatedItem() {
        var item = this.crud.form.detail_register.selectedProduct
        var status = true
        if(this.crud.form.detail) {
          this.crud.form.detail.forEach(element => {                        
            if(element.product.source=='product') {
              if(element.product.id == item.id) {
                status = false
              }              
            } 
            
            if(element.product.source=='colors_waist') {
              if(element.product.id == item.id &&
                 element.product.products_id == item.products_id) {
                status = false
              }              
            }

            if(element.product.source=='colors') {
              if(element.product.id == item.id &&
                 element.product.products_id == item.products_id) {
                   status = false
                 }              
            }

            if(element.product.source=='waist') {
              if(element.product.id == item.id &&
                 element.product.products_id == item.products_id) {              
                  status = false
              }              
            }
          });
        }
        return status
      },     
      
      // FACILITY
      saveFacility() {        
        if(this.crud.facility.products) {
          this.crud.facility.products.forEach(element => {                       
            if(parseFloat(element.quantity)) {                   
              this.crud.form.detail_register.quantity = element.quantity     
              this.crud.form.detail_register.quantityOld = element.quantity             
              this.crud.form.detail_register.selectedProduct = element.product
              this.crud.form.detail_register.currency = element.currency
              this.crud.form.detail_register.amount = element.amount
              this.crud.form.detail_register.discount = element.discount
              this.crud.form.detail_register.unit_measurement = element.unit_measurement
              this.addItem()
            } 
          });

          this.cancelFacility()
        }        
      },
      cancelFacility() {
        this.crud.form.detail_register.forceRenderSelectProduct = 0
        this.loadProducts(null)                                    
        
        this.crud.form.detail_register.button.editing = false 

        this.modal.facility.active = false
      },
      showItemCrudFacilityColors(item) {
        var status = true
       
        if(this.crud.form.detail) {
          this.crud.form.detail.forEach(element => {    
            if(element.product.source == 'colors') {
              if(element.product.id == item.id) {
                status = false
              }
            }
          });
        }
        return status
      },
      showItemCrudFacilityWaist(item) {
        var status = true
       
        if(this.crud.form.detail) {
          this.crud.form.detail.forEach(element => {    
            if(element.product.source == 'waist') {
              if(element.product.id == item.id) {
                status = false
              }
            }
          });
        }
        return status
      },      

      // LOAD EQUIVALENCES
      loadEquivalences() {        
        this.arr.equivalences = []

        if(this.crud.form.detail_register.selectedProduct) {        
          if(this.crud.form.detail_register.selectedProduct.unit_measurement_purchase_default) {            
            var result = serviceAPI.obtenerEquivalencesAvailable({
              unit_measurement_id: this.crud.form.detail_register.selectedProduct.unit_measurement_purchase_default.id,
            })

            result.then((response) => {
              var data = response.data
              this.arr.equivalences = data
            })      
          }
        }
      },
      setUnitMeasurementAlternativeRecalculePrice(element) {        
        var result = serviceAPI.recalculePriceByUnitMeasurementAlternative({
          products_id: this.crud.form.detail_register.selectedProduct.id,
          price: this.crud.form.detail_register.amount_initial,
          uni: this.crud.form.detail_register.selectedProduct.unit_measurement_purchase_default.id,
          unr: element.id,
        })

        result.then((response) => {
          var data = response.data
          this.crud.form.detail_register.amount = data
          
          this.crud.form.detail_register.unit_measurement = element
          this.crud.form.detail_register.unit_measurement_id = element.id          
        })                  
      },

      // SCAN CAPTURE BARCODE      
      scanBarcode(e) {        
        // f3 = 114
        // ctrl + f = 70
        if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {           
          e.preventDefault();
          
          if(this.modal.barcode.active) {          
            this.modal.barcode.active = false
            this.modal.barcode.value = ''
            window.removeEventListener('keypress',this.captureBarcode)            
          } else {
            this.modal.barcode.active = true
            this.modal.barcode.value = ''
            window.addEventListener('keypress',this.captureBarcode)                       
          }
        } 
      },
      cancelScanBarcode() {
        this.modal.barcode.active = false
        this.modal.barcode.value = ''
        window.removeEventListener('keypress',this.captureBarcode)        
      },
      captureBarcode(e) {        
        if(e.keyCode==13) {           
          this.modal.barcode.active = false

          var result = serviceAPI.obtenerProductos({
            filter: this.modal.barcode.value,
            where: [{field: 'active', condition: true}],
          })

          result.then((response) => {
            var data = response.data
                        
            if(data && data.length>0) {
              var product = data[0]

              var result1 = serviceAPI.mostrarProducto(product.id, {
                filter: this.modal.barcode.value,
                where: [{field: 'active', condition: true}],
              }) 
              
              result1.then((response1) => {
                var objProduct = response1.data
  
                this.loadProducts(objProduct)
                this.crud.form.detail_register.quantity = 1

                if(this.barcodeAddAutomatic && this.haveOnlyProduct) {
                  setTimeout(() => {
                    this.addItem()
                  }, 500);                
                }
              })
            }            
          })
          return false
        }

        this.modal.barcode.value = this.modal.barcode.value + e.key
      },

      // ACCESO DIRECTO BUSCADOR PRODUCTO
      handlerSearchProducts(e) {
        // f4 = 115
        if (e.keyCode === 115) {                   
          e.preventDefault();
                    
          this.$refs.searchProducts.openSearch()
        } 

        // ctrl + shift + "+" = 107
        if (e.ctrlKey && e.shiftKey && e.keyCode === 107) {                   
          e.preventDefault();
                    
          this.addItem()
        }         
      }
    }
  }
</script>
<style>
  .crud-order-table-detail {
    overflow: auto;
    max-height: 350px;    
    min-height: 100px;    
  }
  .block-product-add {
    background: white;
    filter: brightness(0.4);
    border-radius: 15px;
    padding-top: 10px;
    pointer-events: none;
    opacity: 0.5;
  }
  .block-product-add-div {
    position: absolute;
    text-align: center;
    margin-top: 20px;    
  }
  .crud-new-orders-button-add {
    margin-top: 29px;
  }  
  .curd-detail-product-select-unit-measurement {
    font-size: 10px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .curd-detail-product-select-unit-measurement button {
    font-size: 10px;
    padding-left: 3px;
    padding-right: 3px;
  }
  .crud-detail-product-prepend-amount .input-group-prepend .input-group-text {
    padding-left: 3px !important;
    padding-right: 3px !important;    
  }
  .crud-detail-product-append-discount .input-group-append .input-group-text {
    padding-left: 3px !important;
    padding-right: 3px !important;    
  }  
  .detail-product-multiple-list-price-code {
    color: gray;
  }
  .detail-product-multiple-list-price-title {
    font-size: 15px;
    font-weight: 600;    
  }

  #container_detail_product_select .popover-body {
    padding: 5px;
  }
  .detail-product-stock-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-detail-product-importe-iva {    
    font-weight: 600;
    font-size: 15px;
    color: darkcyan; 
  }  
  .crud-detail-product-fieldset fieldset {
    margin-bottom: 0px !important;
  }
</style>